/* @flow */
import React, { Fragment } from 'react';
import {
    Alert,
    ArrowLeftMd,
    Button,
    H3,
    Inline,
    paletteHelper,
    spacingHelper,
    Select,
    Stack,
    Text,
} from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import CollectionStatement from '../../contact-agent-form/CollectionStatementView';
import type {
    ChangeFormValueType,
    MultipageContactAgentFormFields,
    MultipageFormViewConfig,
    UserContactInfoType,
} from '../View';
import { bedroomOptions, budgetOptions, buyTimePeriod, landSizeOptions } from '../constants';
import { BuyTimePeriodField } from './buy-time-period-field';
type Props = {|
    formState: MultipageContactAgentFormFields,
    onChangePage: (data?: UserContactInfoType) => void,
    onChangeForm: (formKey: string, value: ChangeFormValueType) => void,
    showNetworkErrorMessage: boolean,
    showBadWordErrorMessage: boolean,
    shouldDisableSubmitButton: boolean,
    multipageFormViewConfiguration: MultipageFormViewConfig,
|};

const OptionalMessage = styled(Text).attrs({ forwardedAs: 'span' })`
    color: ${paletteHelper('textSecondary')};
`;

const BackButton = styled(Button)`
    display: flex;
    align-items: center;
`;

const StyledAlert = styled(Alert)`
    && {
        margin-bottom: ${spacingHelper('medium')};
    }
`;

export const Header = () => (
    <Stack gap="medium">
        <div>
            <H3 variant="title02">
                Tell the consultant what you’re looking for! <OptionalMessage>(optional)</OptionalMessage>
            </H3>
        </div>
        <Text>Sharing your property preferences can help the consultant find your dream home.</Text>
    </Stack>
);

export const PageTwo = ({
    formState,
    onChangePage,
    onChangeForm,
    showNetworkErrorMessage,
    showBadWordErrorMessage,
    shouldDisableSubmitButton,
    multipageFormViewConfiguration,
}: Props) => {
    const handleOnchangeForm = (formKey: string) => (value: ChangeFormValueType) => {
        onChangeForm(formKey, value);
    };
    const { bedroom, budget, buyTime, landSize } = formState;
    const { landSizeQuestion, bedroomConfigurationQuestion } = multipageFormViewConfiguration;

    const renderErrorMessage = (showNetworkErrorMessage: boolean, showBadWordErrorMessage: boolean) => {
        if (showBadWordErrorMessage) {
            return (
                <StyledAlert variant="error">
                    Your message contains inappropriate language and couldn&apos;t be sent. Please revise your message
                    and try again.
                </StyledAlert>
            );
        }

        if (showNetworkErrorMessage) {
            return (
                <StyledAlert variant="warning">There was an error sending your message. Please try again.</StyledAlert>
            );
        }

        return null;
    };

    return (
        <Fragment>
            <Stack gap="large">
                <Inline grow={false}>
                    <BackButton
                        id="contactAgentEnquiryPrev"
                        variant="link-primary"
                        sizeVariant="small"
                        type="button"
                        onClick={() => onChangePage()}
                        justifyContent="flex-start"
                    >
                        <ArrowLeftMd />
                        Back
                    </BackButton>
                </Inline>
                <Header />
                {landSizeQuestion && (
                    <Select
                        id="multiPageFormLand"
                        label="What size land are you looking for? (minimum)"
                        options={landSizeOptions}
                        selectedOption={landSizeOptions.find((item) => item.value === landSize.value) || null}
                        onSelectedOptionChange={handleOnchangeForm('landSize')}
                    />
                )}
                {bedroomConfigurationQuestion && (
                    <Select
                        id="multiPageFormBeedrooms"
                        label="How many bedrooms are you looking for?"
                        options={bedroomOptions}
                        selectedOption={bedroomOptions.find((item) => item.value === bedroom.value) || null}
                        onSelectedOptionChange={handleOnchangeForm('bedroom')}
                    />
                )}
                <Select
                    id="multiPageFormBudget"
                    label="What is your budget?"
                    options={budgetOptions}
                    selectedOption={budgetOptions.find((item) => item.value === budget.value) || null}
                    onSelectedOptionChange={handleOnchangeForm('budget')}
                />
                <BuyTimePeriodField list={buyTimePeriod} onChange={handleOnchangeForm('buyTime')} selected={buyTime} />
                {renderErrorMessage(showNetworkErrorMessage, showBadWordErrorMessage)}
                <Button
                    id="contactAgentEnquirySubmit"
                    type="submit"
                    fullWidth={true}
                    disabled={shouldDisableSubmitButton}
                >
                    Send enquiry
                </Button>
            </Stack>
            <CollectionStatement />
        </Fragment>
    );
};
