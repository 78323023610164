/* @flow */
import React from 'react';
import { Modal, Dialog, SuccessLg, paletteHelper, spacingHelper, P } from '@rea-group/construct-kit-core';
import styled from 'styled-components';
import SectionHeading from '../../section-heading';

type Props = {
    consumerName: string,
    onClose: () => void,
};

const ConfirmationIcon = styled(SuccessLg)`
    color: ${paletteHelper('textSuccess')};
    width: 5rem;
    height: 5rem;
    margin-bottom: ${spacingHelper('large')};
`;

const StyledP = styled(P)`
    margin: ${spacingHelper('extraLarge medium')};
`;

const StyledDialog = styled(Dialog)`
    text-align: center;
`;

const View = ({ consumerName, onClose }: Props) => (
    <Modal opened={true} onRequestClose={onClose} getAppElement={() => '#argonaut-wrapper'}>
        <StyledDialog onClose={onClose}>
            <ConfirmationIcon />
            <SectionHeading>{`Thanks ${consumerName}`}</SectionHeading>
            <StyledP>The requested documents have been sent to your email.</StyledP>
        </StyledDialog>
    </Modal>
);

export default View;
