/* @flow */
import React from 'react';
import { compose3 as compose } from '../../../../../../util/functional/compose';
import type { DisplaySuiteData } from '../../types';
import DisplaySuite from '../../../../calendar-events/display-suite-events';
import type { BuyResidentialListingDetailsGetters } from '../../../../../../models/lexa';
import getFragmentData from '../../../../../../util/getFragmentData';
import ChildListingParentDisplaySuite from '../ChildListingParentDisplaySuite.graphql';

type ChildListingDisplaySuiteProps = {
    data: BuyResidentialListingDetailsGetters,
    contactAgentEl: () => ?Element,
};

const adaptLexaData = compose(
    ({ listing, parentDisplaySuiteInspections }) => ({
        listing: listing.parent,
        parentDisplaySuiteInspections,
    }),
    getFragmentData(ChildListingParentDisplaySuite),
    (data: BuyResidentialListingDetailsGetters) => ({
        listing: data.getListing(),
        parentDisplaySuiteInspections: data.getParentDisplaySuiteInspections(),
    })
);

export default ({ data, contactAgentEl }: ChildListingDisplaySuiteProps) => {
    const { parentDisplaySuiteInspections, listing } = adaptLexaData(data);

    if (!parentDisplaySuiteInspections || !listing) {
        return null;
    }
    const displaySuiteData: DisplaySuiteData = {
        listing,
        displaySuiteInspections: parentDisplaySuiteInspections,
        eventTracker: data.getEventTracker(),
    };
    return <DisplaySuite contactAgentEl={contactAgentEl} data={displaySuiteData} shouldHideTitle={true} />;
};
