/* @flow */
import adaptLexaData from './adaptLexaData';
import type { Node } from 'react';
import type { GetListing } from '../../models/lexa';

type Props = {
    data: GetListing,
    multiPageForm: Node,
    singlePageForm: Node,
};

const FormSelector = ({ data, multiPageForm, singlePageForm }: Props) => {
    const { isMultiPageForm } = adaptLexaData(data);
    return isMultiPageForm ? multiPageForm : singlePageForm;
};
export default FormSelector;
