/* @flow */
import getFragmentData from '../../util/getFragmentData';
import { compose3 as compose } from '../../util/functional/compose';
import EnquiryForm from './EnquiryForm.graphql';
import type { GetListing, LexaListing } from '../../models/lexa';

type AdaptedProps = {
    isMultiPageForm: boolean,
};

const adapter = (data) => {
    return { isMultiPageForm: data.viewConfiguration.details.enquiryForm.multiPageForm };
};

export default compose<GetListing, LexaListing, LexaListing, AdaptedProps>(
    adapter,
    getFragmentData(EnquiryForm),
    (data) => data.getListing()
);
