/* @flow */
import React, { Component } from 'react';
import View from './View';
import type { ContainerProps } from './types';

class RequestProjectInfoKitPanel extends Component<ContainerProps> {
    handleRequestInfoKitButton = () => {
        const { firstDocDownloadInputEl, trackRequestInformationKitClick } = this.props;
        const firstInputEl = firstDocDownloadInputEl && firstDocDownloadInputEl();
        trackRequestInformationKitClick();
        firstInputEl && firstInputEl.focus();
    };

    render() {
        return <View {...this.props} onRequestProjectInfoClick={this.handleRequestInfoKitButton} />;
    }
}

export default RequestProjectInfoKitPanel;
