/* @flow */
import React from 'react';
import styled from 'styled-components';
import { ExternalLinkMd, Link, paletteHelper, spacingHelper, Text } from '@rea-group/construct-kit-core';

type ViewProps = {
    title: string,
    location: string,
    getDirectionsUrl: ?string,
    className: string,
    getDirectionsTracker: () => void,
    isTabAddress?: boolean,
};

const AddressContainer = styled.div`
    background: ${paletteHelper('backgroundSecondaryBase')};
    border-radius: 1rem;
    padding: ${spacingHelper('medium')};
    margin: ${spacingHelper('medium 0')};
`;

const GetDirectionsIconLink = styled(ExternalLinkMd)`
    margin-left: ${(props) => props.theme.spacing.twoExtraSmall};
    transform: translateY(2px);
`;

const Title = styled(Text)`
    margin-bottom: ${spacingHelper('extraSmall')};
`;

const Location = styled(Text)`
    margin-bottom: ${spacingHelper('extraSmall')};
    ${({ theme }) => theme.breakpoints.large} {
        margin-bottom: ${spacingHelper('medium')};
    }
`;

const AddressAndLinkContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.large} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > p {
            margin: 0;
        }
    }
`;

const GetDirections = ({ getDirectionsUrl, getDirectionsTracker }) =>
    getDirectionsUrl ? (
        <Link href={getDirectionsUrl} rel="noopener noreferrer nofollow" target="_blank" onClick={getDirectionsTracker}>
            <Text as="span" variant="subtitle01">
                Get directions
            </Text>
            <GetDirectionsIconLink />
        </Link>
    ) : null;

export default ({ title, location, className, getDirectionsUrl, getDirectionsTracker, isTabAddress }: ViewProps) => {
    if (isTabAddress) {
        return (
            <AddressContainer>
                <Title variant="overline">{title}</Title>
                <AddressAndLinkContainer>
                    <Location>{location}</Location>
                    <GetDirections getDirectionsUrl={getDirectionsUrl} getDirectionsTracker={getDirectionsTracker} />
                </AddressAndLinkContainer>
            </AddressContainer>
        );
    }
    return (
        <div className={`project-display-address ${className}`}>
            <Title variant="overline">{title}</Title>
            <Location>{location}</Location>
            <GetDirections getDirectionsUrl={getDirectionsUrl} getDirectionsTracker={getDirectionsTracker} />
        </div>
    );
};
