/* @flow */
import type { RenderOption } from './types';
import capitalize from 'lodash/capitalize';
import type { AddressType } from '../types';
import type { BuyResidentialListingDetailsGetters, ProjectProfile } from '../../../../../models/lexa';

const getRenderOption = (
    isParentDisplaySuiteInspectionsExists,
    displayLocation,
    maybeDisplayAddress,
    hasOwnInspections
): RenderOption => {
    if (!(isParentDisplaySuiteInspectionsExists && displayLocation)) {
        return {
            renderCase: 'INSPECTIONS_ONLY',
            eventListTitle: 'Inspections',
            displayAddress: null,
        };
    }

    if (hasOwnInspections) {
        return {
            renderCase: 'TAB',
            projectTabTitle: capitalize(displayLocation.title),
            displayAddress: ((maybeDisplayAddress: any): AddressType),
        };
    }

    return {
        renderCase: 'DISPLAY_SUITES_ONLY',
        eventListTitle: `${capitalize(displayLocation.title)} open hours`,
        displayAddress: ((maybeDisplayAddress: any): AddressType),
    };
};

export default (data: BuyResidentialListingDetailsGetters, maybeDisplayAddress: ?AddressType) => {
    const { displayLocation, _links } = ((data.getListing().parent: any): ProjectProfile);
    const hasOwnInspections = data.getInspections().length > 0;
    const isParentDisplaySuiteInspectionsExists = !!data.getParentDisplaySuiteInspections();

    return {
        renderOption: getRenderOption(
            isParentDisplaySuiteInspectionsExists,
            displayLocation,
            maybeDisplayAddress,
            hasOwnInspections
        ),
        parentPath: _links.canonical.path,
    };
};
