/* @flow */
import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { ProjectProfileProps } from './types';

export default ({
    data,
    inspectionsEl,
    setMapEl,
    contactAgentEl,
    inspectionTimesEl,
    setInspectionTimesEl,
}: ProjectProfileProps) => {
    return (
        <View
            data={data}
            inspectionsEl={inspectionsEl}
            {...adaptLexaData(data)}
            setMapEl={setMapEl}
            contactAgentEl={contactAgentEl}
            inspectionTimesEl={inspectionTimesEl}
            setInspectionTimesEl={setInspectionTimesEl}
        />
    );
};
