/* @flow */
import React, { Component } from 'react';
import View from './View';
import type { Props } from './types';

type State = {
    consumerName: string,
    shouldShowConfirmationModal: boolean,
};

class DocDownloadContainer extends Component<Props, State> {
    state = {
        consumerName: '',
        shouldShowConfirmationModal: false,
    };

    showConfirmationModal = (consumerName: string) => {
        this.setState({
            consumerName,
            shouldShowConfirmationModal: true,
        });
    };

    closeConfirmationModal = () => {
        this.setState({ shouldShowConfirmationModal: false });
    };

    handleDocumentClick = () => {
        const { firstDocDownloadInputEl, trackDocumentClick } = this.props;
        const firstInputEl = firstDocDownloadInputEl && firstDocDownloadInputEl();
        trackDocumentClick();
        firstInputEl && firstInputEl.focus();
    };

    render() {
        const { setFirstDocDownloadInputEl } = this.props;
        return (
            <View
                {...this.state}
                {...this.props}
                setFirstInputEl={setFirstDocDownloadInputEl}
                showConfirmationModal={this.showConfirmationModal}
                closeConfirmationModal={this.closeConfirmationModal}
                handleDocumentClick={this.handleDocumentClick}
            />
        );
    }
}

export default DocDownloadContainer;
