/* @flow */
import React from 'react';
import FormInput from './FormInput';
import CollectionStatement from '../collection-statement';
import {
    StyledRequestDocsEmail,
    StyledRequestDocsMobile,
    StyledRequestDocsHiddenElement,
    StyledRequestDocsErrorMessage,
    StyledRequestDocsFooter,
    SendRequestButton,
} from './styles';

type Props = {
    name: string,
    email: string,
    mobile: string,
    consumerComment: string,
    shouldDisableSubmitButton: boolean,
    showNetworkErrorMessage: boolean,
    validationError: {
        isNameBlank: boolean,
        doesNameContainUrl: boolean,
        isEmailBlank: boolean,
        isEmailInvalid: boolean,
        isPhoneInvalid: boolean,
    },
    handleNameChange: (e: SyntheticInputEvent<>) => void,
    handleEmailChange: (e: SyntheticInputEvent<>) => void,
    handleMobileChange: (e: SyntheticInputEvent<>) => void,
    handleConsumerCommentChange: (e: SyntheticInputEvent<>) => void,
    handleSubmit: () => void,
    setFirstInputEl: (?HTMLElement) => void,
};

export default ({
    name,
    email,
    mobile,
    consumerComment,
    showNetworkErrorMessage,
    validationError,
    shouldDisableSubmitButton,
    handleNameChange,
    handleEmailChange,
    handleMobileChange,
    handleConsumerCommentChange,
    handleSubmit,
    setFirstInputEl,
}: Props) => {
    const { isNameBlank, doesNameContainUrl, isEmailBlank, isEmailInvalid, isPhoneInvalid } = validationError;
    const emailError = isEmailBlank || isEmailInvalid;
    const errorBlankNameMessage = 'Please provide your name.';
    const errorURLNameMessage = doesNameContainUrl
        ? `We can't process your message if it contains a URL or dots between characters. Try using a space to separate.`
        : '';
    const errorNameMessage = isNameBlank ? errorBlankNameMessage : errorURLNameMessage;
    const errorEmailMessage = emailError ? 'Please provide a valid email address.' : '';
    const errorPhoneMessage = isPhoneInvalid ? 'Please provide a valid phone number or leave this field blank.' : '';

    return (
        <form className="request-docs-form" id="requestDocs">
            <div className="request-docs-form__name">
                <FormInput
                    setInputEl={setFirstInputEl}
                    handleChange={handleNameChange}
                    value={name}
                    placeholder="Enter your name"
                    type="text"
                    field="Name"
                    required={true}
                    errorMessage={errorNameMessage}
                />
            </div>
            <StyledRequestDocsEmail>
                <FormInput
                    handleChange={handleEmailChange}
                    value={email}
                    placeholder="Enter your email address"
                    type="email"
                    field="Email"
                    required={true}
                    errorMessage={errorEmailMessage}
                />
            </StyledRequestDocsEmail>
            <StyledRequestDocsMobile>
                <FormInput
                    handleChange={handleMobileChange}
                    value={mobile}
                    placeholder="Enter your phone number"
                    type="tel"
                    field="Phone number"
                    errorMessage={errorPhoneMessage}
                />
            </StyledRequestDocsMobile>
            <StyledRequestDocsHiddenElement className="request-docs-form__consumer-comment">
                <FormInput
                    handleChange={handleConsumerCommentChange}
                    value={consumerComment}
                    type="text"
                    field="Consumer comment"
                />
            </StyledRequestDocsHiddenElement>
            {showNetworkErrorMessage && (
                <StyledRequestDocsErrorMessage>
                    There was an error sending your message. Please try again.
                </StyledRequestDocsErrorMessage>
            )}
            <StyledRequestDocsFooter>
                <SendRequestButton type="button" onClick={handleSubmit} disabled={shouldDisableSubmitButton}>
                    Send request
                </SendRequestButton>
                <CollectionStatement />
            </StyledRequestDocsFooter>
        </form>
    );
};
