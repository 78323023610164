/* @flow */
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextInput, typographyHelper, paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';

const RequiredLabel = styled.span`
    ${typographyHelper('body02')}
    color: ${paletteHelper('textSecondary')};
`;

const StyledTextInput = styled(TextInput)`
    padding-top: ${spacingHelper('large')};
    padding-bottom: ${spacingHelper('twoExtraSmall')};
`;

type InputType = 'email' | 'text' | 'tel';

type Props = {
    field: string,
    type: InputType,
    value: string,
    placeholder?: string,
    handleChange: Function,
    errorMessage?: string,
    required?: boolean,
    setInputEl?: (?HTMLElement) => void,
};

const FormInput = (props: Props) => {
    const [placeholder, setPlaceholder] = useState(props.placeholder);

    const onFocus = () => {
        setPlaceholder('');
    };

    const onBlur = () => {
        setPlaceholder(props.placeholder);
    };

    const { field, type, value, required, handleChange, setInputEl, errorMessage } = props;

    const label = required ? (
        <React.Fragment>
            {field}
            <RequiredLabel> (required)</RequiredLabel>
        </React.Fragment>
    ) : (
        field
    );

    return (
        <StyledTextInput
            label={label}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            type={type}
            ref={setInputEl}
            onBlur={onBlur}
            onFocus={onFocus}
            icon={false}
            errorMessage={errorMessage}
            autoValidate={false}
        />
    );
};

export default FormInput;
