/* @flow */
import React from 'react';
import View from './View';

type DataType = {
    address: ?{
        title: string,
        location: string,
        getDirectionsUrl: ?string,
    },
    className?: string,
    getDirectionsTracker: () => void,
    isTabAddress?: boolean,
};

export default ({ address, className = '', getDirectionsTracker, isTabAddress }: DataType) => {
    return address ? (
        <View
            {...address}
            className={className}
            getDirectionsTracker={getDirectionsTracker}
            isTabAddress={isTabAddress}
        />
    ) : null;
};
