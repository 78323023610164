/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { AdaptedListingData, ProjectProfileDataType } from './types';
import type { ProjectProfileMedia } from '../../../models/lexa';
import ProjectProfileRequestInfoKit from './ProjectProfileRequestInfoKit.graphql';

type RequiredData = {
    name: string,
    media: ProjectProfileMedia,
};

const dataAdapter = ({ name, media }: RequiredData): AdaptedListingData => {
    if (!media.enquiryDocuments) return null;
    return {
        projectName: name,
    };
};

const getListingData = compose(dataAdapter, getFragmentData(ProjectProfileRequestInfoKit), (data) => data.getListing());

const getEventTrackerData = (data: ProjectProfileDataType) => {
    return {
        trackRequestInformationKitClick: () =>
            data.getEventTracker().requestProjectInfoClick('project_contact_card_click'),
    };
};

export default (data: ProjectProfileDataType) => {
    const listingData = getListingData(data);
    return listingData
        ? {
              ...getListingData(data),
              ...getEventTrackerData(data),
          }
        : null;
};
