/* @flow */
import React from 'react';
import type { ViewProps } from './types';
import './styles.scss';
import ScrollToElement from '../../ScrollToElement';
import { Button } from '@rea-group/construct-kit-core';

export default class ScrollableRequestInfoKitButton extends React.Component<ViewProps> {
    onButtonWithScrollClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    render = () => (
        <div className="request-info-kit">
            <ScrollToElement elementRef={this.props.docDownloadEl} afterScroll={this.onButtonWithScrollClick}>
                {(scrollOnClick) => (
                    <Button variant="outline" sizeVariant="small" fullWidth={true} onClick={scrollOnClick}>
                        <span>Request an information kit</span>
                    </Button>
                )}
            </ScrollToElement>
        </div>
    );
}
