/* @flow */
import React from 'react';
import styled from 'styled-components';
import { Radio, spacingHelper, Stack, Text } from '@rea-group/construct-kit-core';
import { currentLayout, LAYOUT_DEFINITION } from '../../../../../client/device-capability';

type Props = {|
    list: Array<string>,
    onChange: (selected: string) => void,
    selected: string,
|};

const ListWrap = styled.div`
    display: flex;
    flex-direction: column;
    > {
        &:not(:last-child) {
            margin-bottom: ${spacingHelper('medium')};
        }
    }
    ${({ theme }) => theme.breakpoints.large} {
        flex-direction: row;
        justify-content: space-between;
        > {
            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }
`;

export const BuyTimePeriodField = ({ list, onChange, selected }: Props) => {
    const handleOnClick = (event) => {
        onChange(event.target.value);
    };
    const screen = currentLayout();
    const radioSize = screen === LAYOUT_DEFINITION.L || screen === LAYOUT_DEFINITION.XL ? 'large' : 'small';
    return (
        <Stack gap="twoExtraSmall">
            <Text variant="subtitle01">When do you want to buy?</Text>
            <ListWrap>
                {list.map((item, index: number) => (
                    <Radio
                        id={`buy-time-period-${index}`}
                        name="buy-time-period"
                        sizeVariant={radioSize}
                        label={item}
                        value={item}
                        key={item}
                        onChange={handleOnClick}
                        checked={selected === item}
                    />
                ))}
            </ListWrap>
        </Stack>
    );
};
