/* @flow */
import React, { type Node } from 'react';
import type { AddressType } from '../../types';
import Address from '../address';
import '../../style.scss';
import useScrollToElement from '../../../../useScrollToElement';

type EventListWithAddressProps = {
    propertyOrProjectAddress: AddressType,
    displayAddress?: AddressType,
    getDirectionsTracker: () => void,
    children: Node,
    eventListEl: () => ?Element,
    setEventListEl: (?Element) => void,
};
const EventListWithAddress = ({
    propertyOrProjectAddress,
    getDirectionsTracker,
    displayAddress,
    children,
    eventListEl,
    setEventListEl,
}: EventListWithAddressProps) => {
    useScrollToElement(() => window.location.search.includes('scrollTo=inspection-times'), eventListEl);
    return (
        <div className="supplementary-info">
            <div className="supplementary-info__address-container">
                <Address
                    className="supplementary-info__project-address"
                    address={propertyOrProjectAddress}
                    getDirectionsTracker={getDirectionsTracker}
                />
                <Address
                    className="supplementary-info__display-address"
                    address={displayAddress}
                    getDirectionsTracker={getDirectionsTracker}
                />
            </div>
            <div ref={setEventListEl}>{children}</div>
        </div>
    );
};

export default EventListWithAddress;
