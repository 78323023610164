/* @flow */
import React from 'react';
import adaptLexaData from './adaptLexaData';
import View from './View';
import type { DataType } from './types';

export default ({ data, inspectionsEl, contactAgentEl, inspectionTimesEl, setInspectionTimesEl }: DataType) => {
    const adaptedData = adaptLexaData(data);
    return adaptedData ? (
        <View
            {...adaptedData}
            data={data}
            inspectionsEl={inspectionsEl}
            contactAgentEl={contactAgentEl}
            inspectionTimesEl={inspectionTimesEl}
            setInspectionTimesEl={setInspectionTimesEl}
        />
    ) : null;
};
