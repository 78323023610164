/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { BuyResidentialListingDataType, AdaptedListingData, AdaptedData } from './types';
import type { ProjectProfile } from '../../../models/lexa/listingType';
import ChildListingDocDownload from './ChildListingDocDownload.graphql';

type RequiredData = {
    parent: ?ProjectProfile,
    id: string,
};

const dataAdapter = ({ id, parent }: RequiredData): ?AdaptedListingData => {
    if (parent && parent.media.enquiryDocuments) {
        return {
            listingId: id,
            childListingId: id,
            projectName: parent.name,
            documentList: parent.media.enquiryDocuments.items,
            requestDocsUrl: parent.media.enquiryDocuments.url.href,
        };
    }
    return null;
};

const getListingData = compose(dataAdapter, getFragmentData(ChildListingDocDownload), (data) => data.getListing());
const getEventTrackerData = (data) => ({
    onDocDownload: data.getEventTracker().docDownloadRequest,
    trackDocumentClick: () => data.getEventTracker().requestProjectInfoClick('project_doc_download_name_click'),
});

export default (data: BuyResidentialListingDataType): ?AdaptedData => {
    const listingData = getListingData(data);
    return listingData
        ? {
              ...listingData,
              ...getEventTrackerData(data),
          }
        : null;
};
