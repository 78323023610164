/* @flow */
import React from 'react';
import View from './View';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';

import type { GetBuyResidentialListing, GetProjectProfile } from '../../models/lexa';

type ResidentialListingDataProps = {
    data: GetBuyResidentialListing,
};

type ProjectProfileDataProps = {
    data: GetProjectProfile,
};

export const ChildListingParentProjectBanner = ({ data }: ResidentialListingDataProps) => {
    const adaptedData = adaptResidentialListingData(data);
    if (adaptedData) {
        return <View {...adaptedData} />;
    }
    return null;
};

export const ProjectBanner = ({ data }: ProjectProfileDataProps) => {
    const adaptedData = adaptProjectProfileData(data);
    if (adaptedData) {
        return <View {...adaptedData} />;
    }
    return null;
};
