/* @flow */
import type {
    AdaptedAddressData,
    AdaptedLexaData,
    AdaptedOpeningHoursData,
    RequiredAddressData,
    RequiredData,
    ChildListingRequiredData,
} from './types';
import type { DisplayLocation, GetEventTracker } from '../../../models/lexa';
import type { ViewEmailAgentPageSource } from '../../../client/tracking/event/types';

const GOOGLE_MAP_DIRECTIONS_URL = 'https://www.google.com/maps/dir/?api=1';
const POSTCODES_FOR_PRIVATE_INSPECTION = [...Array.from(Array(208), (_, i) => i + 3000), 3753, 3756];

export const projectAdapter = (projectProfile: ?RequiredData): ?AdaptedLexaData => {
    if (!projectProfile) return null;
    const { name, displayLocation, address } = projectProfile;
    const title = `${name} locations`;
    let textInCTA = 'Request an appointment';
    if (displayLocation) {
        const { postcode } = displayLocation.address;
        textInCTA = POSTCODES_FOR_PRIVATE_INSPECTION.includes(parseInt(postcode))
            ? 'Request a private appointment'
            : textInCTA;
    }
    return {
        title,
        textInCTA,
        propertyOrProjectAddress: addressAdapter({ address, title: 'PROJECT ADDRESS' }),
        displayAddress:
            displayLocation &&
            addressAdapter({
                address: displayLocation.address,
                title: `${displayLocation.title.toUpperCase()} ADDRESS`,
            }),
        openingHours: displayLocation && openingHoursAdapter(displayLocation),
    };
};

const addressAdapter = ({ address, title }: RequiredAddressData): AdaptedAddressData => {
    const {
        suburb,
        state,
        postcode,
        display: { geocode, shortAddress },
    } = address;
    const fullDisplaySuburb = `${suburb}, ${state} ${postcode}`;
    const location = shortAddress ? `${shortAddress}, ${fullDisplaySuburb}` : fullDisplaySuburb;
    const getDirectionsUrl =
        geocode &&
        `${GOOGLE_MAP_DIRECTIONS_URL}&destination=${geocode.latitude},${geocode.longitude}&travelmode=driving`;
    return {
        title,
        location,
        getDirectionsUrl,
    };
};

const openingHoursAdapter = (displayLocation: DisplayLocation): AdaptedOpeningHoursData => {
    const { title, openingHours } = displayLocation;
    let description = displayLocation.description && displayLocation.description.trim();

    if (description === '') {
        description = null;
    }

    return {
        title: `${title.toUpperCase()} OPEN HOURS`,
        items: openingHours.map((oh) => oh.display),
        description,
    };
};

export const getEventTrackerData = ({ getEventTracker }: GetEventTracker) => ({
    getDirectionsTracker: () => getEventTracker().getDirections(),
    viewEmailAgentTracker: (inPageSource: ViewEmailAgentPageSource) =>
        getEventTracker().viewEmailAgentForm(inPageSource),
});

export const childListingAdapter = ({
    listing: childListing,
    inspections,
    parentDisplaySuiteInspections,
}: ChildListingRequiredData) => {
    const parentProjectProfile = childListing.parent;

    if (!parentProjectProfile) return null;

    const { displayLocation: projectDisplayLocation, name: projectName } = parentProjectProfile;
    const displayLocationTitle = projectDisplayLocation ? projectDisplayLocation.title : '';
    const shouldRenderTab = inspections.length > 0 && parentDisplaySuiteInspections && displayLocationTitle !== '';
    return {
        ...projectAdapter(parentProjectProfile),
        propertyOrProjectAddress: addressAdapter({ address: childListing.address, title: 'PROPERTY ADDRESS' }),
        title: shouldRenderTab
            ? `${projectName} inspection & ${displayLocationTitle} times`
            : `${projectName} locations`,
    };
};
