/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type {
    GetEventTracker,
    GetBuyResidentialListing,
    ResidentialListingMedia,
    ProjectProfile,
} from '../../../models/lexa';
import type { ViewProps } from './types';
import ResidentialStatementOfInformation from './ResidentialStatementOfInformation.graphql';

type RequiredData = {
    media: ResidentialListingMedia,
    parent: ?ProjectProfile,
};

type DataType = GetEventTracker & GetBuyResidentialListing;

const dataAdapter = ({ media, parent }: RequiredData) => {
    const href = media.statementOfInformation ? media.statementOfInformation.href : null;

    const displayText = parent ? 'View statement of information' : 'Price guide details';
    return { href, displayText };
};

const getListingData = compose(
    dataAdapter,
    getFragmentData(ResidentialStatementOfInformation),
    (data: GetBuyResidentialListing) => data.getListing()
);

const getEventTrackerData = (data) => ({ onClick: data.getEventTracker().statementOfInformationLinkClick });

export default (data: DataType): ViewProps => ({
    ...getListingData(data),
    ...getEventTrackerData(data),
});
