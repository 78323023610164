/* @flow */
import React from 'react';
import View from './View';
import DividedView from './DividedView';
import { default as adaptResidentialListingData } from './adaptResidentialListingData';
import { default as adaptProjectProfileData } from './adaptProjectProfileData';
import type { GetBuyResidentialListing, GetProjectProfile, GetEventTracker } from '../../../models/lexa';

type ResidentialListingDataProps = {
    data: GetBuyResidentialListing & GetEventTracker,
    className?: string,
};

type ProjectProfileDataProps = {
    data: GetProjectProfile & GetEventTracker,
    className?: string,
};

export const ResidentialListingStatementOfInformation = ({ data, className }: ResidentialListingDataProps) => {
    return <View {...adaptResidentialListingData(data)} className={className} />;
};

export const ProjectProfileStatementOfInformation = ({ data, className }: ProjectProfileDataProps) => {
    return <View {...adaptProjectProfileData(data)} className={className} />;
};

export const ResidentialListingDividedStatementOfInformation = ({ data }: ResidentialListingDataProps) => {
    return <DividedView {...adaptResidentialListingData(data)} />;
};
