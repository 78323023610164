/* @flow */
import type { Node } from 'react';
import React, { useContext } from 'react';
import {
    default as adaptProjectProfileData,
    getTracker as getProjectProfileTracker,
} from '../contact-agent-form/adaptProjectProfileData';
import agentContactService from '../contact-agent-form/service';
import {
    default as adaptResidentialListingData,
    getTracker as getResidentialListingTracker,
} from '../contact-agent-form/adaptResidentialListingData';
import type { ProjectProfileProps, ResidentialListingProps } from '../contact-agent-form';
import { useConsumerRequestsContext } from '../contact-agent-form/ConsumerRequestsProvider';
import { default as adaptMultiPageData, getEmailLeadToAgentInteractionTracker } from './adaptMultiPageData';
import { View } from './View';
import type { Props } from './View';
import ChannelContext from '../../../../util/ChannelContext';
import type { MultiPageFormTrackers } from './types';
import { ViewWithLogin } from './ViewWithLogin';

type ProjectProfileMultiPageFormProps = {
    ...ProjectProfileProps,
    agentAndListingInfo: Node,
};

type ResidentialListingMultiPageFormProps = {
    ...ResidentialListingProps,
    agentAndListingInfo: Node,
};

export const MultiPageForm = (props: Props) => {
    const consumerRequestsContext = useConsumerRequestsContext();
    const { bedroomConfigurationQuestion, landSizeQuestion, preEnquiryLogin, shouldRenderAgentContactForm } =
        adaptMultiPageData(props.channel)(props.data);
    const propsWithConsumerRequestsContext = {
        ...props,
        consumerRequestsContext: consumerRequestsContext,
        multipageFormViewConfiguration: {
            bedroomConfigurationQuestion,
            landSizeQuestion,
            preEnquiryLogin,
        },
    };

    if (shouldRenderAgentContactForm) {
        return preEnquiryLogin ? (
            <ViewWithLogin {...propsWithConsumerRequestsContext} />
        ) : (
            <View {...propsWithConsumerRequestsContext} />
        );
    } else {
        return null;
    }
};
export const ProjectMultiPageForm = (props: ProjectProfileMultiPageFormProps) => {
    const channel = useContext(ChannelContext);

    const { data } = props;
    const adaptedData = adaptProjectProfileData(channel)(data);

    const multiPageFormTrackers: MultiPageFormTrackers = {
        emailLeadToAgentInteractionTracker: getEmailLeadToAgentInteractionTracker(data),
        formSubmittedTracker: getProjectProfileTracker(data),
    };

    return (
        <MultiPageForm
            {...props}
            {...adaptedData}
            agentContactService={agentContactService}
            multiPageFormTrackers={multiPageFormTrackers}
            channel={channel}
        />
    );
};

export const ResidentialMultiPageForm = (props: ResidentialListingMultiPageFormProps) => {
    const channel = useContext(ChannelContext);

    const { data } = props;
    const adaptedData = adaptResidentialListingData(channel)(data);

    const multiPageFormTrackers: MultiPageFormTrackers = {
        emailLeadToAgentInteractionTracker: getEmailLeadToAgentInteractionTracker(data),
        formSubmittedTracker: getResidentialListingTracker(data),
    };

    return (
        <MultiPageForm
            {...props}
            {...adaptedData}
            agentContactService={agentContactService}
            multiPageFormTrackers={multiPageFormTrackers}
            channel={channel}
        />
    );
};
