/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { AdaptedListingData, BuyResidentialListingDataType } from './types';
import type { ProjectProfile } from '../../../models/lexa';
import ChildListingRequestInfoKit from './ChildListingRequestInfoKit.graphql';

type RequiredData = {
    parent: ?ProjectProfile,
};

const dataAdapter = ({ parent }: RequiredData): AdaptedListingData => {
    if (parent && parent.media.enquiryDocuments)
        return {
            projectName: parent.name,
        };

    return null;
};

const getListingData = compose(dataAdapter, getFragmentData(ChildListingRequestInfoKit), (data) => data.getListing());

const getEventTrackerData = (data: BuyResidentialListingDataType) => {
    return {
        trackRequestInformationKitClick: () =>
            data.getEventTracker().requestProjectInfoClick('project_contact_card_click'),
    };
};

export default (data: BuyResidentialListingDataType) => {
    const listingData = getListingData(data);
    return listingData
        ? {
              ...getListingData(data),
              ...getEventTrackerData(data),
          }
        : null;
};
