/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetEventTracker, GetProjectProfile, ProjectProfileMedia } from '../../../models/lexa';
import type { ViewProps } from './types';
import ProjectProfileStatementOfInformation from './ProjectProfileStatementOfInformation.graphql';

type RequiredData = {
    media: ProjectProfileMedia,
};

type DataType = GetEventTracker & GetProjectProfile;

const dataAdapter = ({ media }: RequiredData) => {
    const href = media.statementOfInformation ? media.statementOfInformation.href : null;

    return { href };
};

const getListingData = compose(
    dataAdapter,
    getFragmentData(ProjectProfileStatementOfInformation),
    (data: GetProjectProfile) => data.getListing()
);

const getEventTrackerData = (data) => ({ onClick: data.getEventTracker().statementOfInformationLinkClick });

export default (data: DataType): ViewProps => ({
    ...getListingData(data),
    ...getEventTrackerData(data),
});
