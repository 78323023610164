/* @flow */
import React from 'react';
import './styles.scss';
import { TemplatedLazyImage } from '../Image';

type PropsType = {
    templatedUrl: string,
};

export default ({ templatedUrl }: PropsType) => (
    <div className="project-banner">
        <TemplatedLazyImage
            className="project-banner__image"
            templatedUrl={templatedUrl}
            size="640x57"
            avif={true}
            options="resize"
            alt=""
        />
    </div>
);
