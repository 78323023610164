/* @flow */
import React, { type Node } from 'react';
import type { BuyResidentialListingDetailsGetters } from '../../../../../models/lexa';
import type { AddressType } from '../types';
import adaptData from './adaptData';
import EventListWithAddress from '../sharedComponents/event-list-with-address';
import SectionHeading from '../../../section-heading';
import { EventTip, StyledDivider } from '../sharedComponents';
import Inspection from '../../../calendar-events/open-for-inspection';
import ChildListingDisplaySuite from './child-listing-display-suite';
import EventListTab from './event-list-tab';
import { FeatureToggle } from '../../../../toggles';

type ChildListingSupplementaryInfoProps = {
    data: BuyResidentialListingDetailsGetters,
    inspectionsEl: Node,
    contactAgentEl: () => ?Element,
    displayAddress: ?AddressType,
    propertyAddress: AddressType,
    getDirectionsTracker: () => void,
    inspectionTimesEl: () => ?Element,
    setInspectionTimesEl: (?Element) => void,
};

const ChildListingSupplementaryInfo = ({
    data,
    inspectionsEl,
    contactAgentEl,
    displayAddress: maybeDisplayAddress,
    propertyAddress,
    getDirectionsTracker,
    inspectionTimesEl,
    setInspectionTimesEl,
}: ChildListingSupplementaryInfoProps) => {
    const { renderOption, parentPath } = adaptData(data, maybeDisplayAddress);

    if (renderOption.renderCase === 'INSPECTIONS_ONLY') {
        return (
            <EventListWithAddress
                propertyOrProjectAddress={propertyAddress}
                getDirectionsTracker={getDirectionsTracker}
                eventListEl={inspectionTimesEl}
                setEventListEl={setInspectionTimesEl}
            >
                <FeatureToggle toggleName={'newEvents'}>
                    {(isEventsToggleOn) =>
                        isEventsToggleOn ? (
                            <React.Fragment>
                                <StyledDivider />
                                {inspectionsEl}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <StyledDivider />
                                <SectionHeading>{renderOption.eventListTitle}</SectionHeading>
                                <EventTip projectPath={parentPath} />
                                <Inspection
                                    data={data}
                                    contactAgentEl={contactAgentEl}
                                    id="child-listing-inspections-list"
                                    shouldHideTitle={true}
                                />
                            </React.Fragment>
                        )
                    }
                </FeatureToggle>
            </EventListWithAddress>
        );
    }

    if (renderOption.renderCase === 'DISPLAY_SUITES_ONLY') {
        return (
            <EventListWithAddress
                propertyOrProjectAddress={propertyAddress}
                displayAddress={renderOption.displayAddress}
                getDirectionsTracker={getDirectionsTracker}
                eventListEl={inspectionTimesEl}
                setEventListEl={setInspectionTimesEl}
            >
                <FeatureToggle toggleName={'newEvents'}>
                    {(isEventsToggleOn) =>
                        isEventsToggleOn ? (
                            <React.Fragment>
                                <StyledDivider />
                                {inspectionsEl}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <StyledDivider />
                                <SectionHeading>{renderOption.eventListTitle}</SectionHeading>
                                <EventTip projectPath={parentPath} />
                                <ChildListingDisplaySuite data={data} contactAgentEl={contactAgentEl} />
                            </React.Fragment>
                        )
                    }
                </FeatureToggle>
            </EventListWithAddress>
        );
    }

    return (
        <EventListTab
            data={data}
            inspectionsEl={inspectionsEl}
            propertyAddress={propertyAddress}
            displayAddress={renderOption.displayAddress}
            contactAgentEl={contactAgentEl}
            getDirectionsTracker={getDirectionsTracker}
            parentPath={parentPath}
            projectTabTitle={renderOption.projectTabTitle}
            eventListEl={inspectionTimesEl}
            setEventListEl={setInspectionTimesEl}
        />
    );
};

export default ChildListingSupplementaryInfo;
