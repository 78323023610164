/* @flow */
import React from 'react';
import { ProjectMap as Map } from '../../map/index';
import type { ViewProps } from './types';
import SectionHeading from '../../section-heading';
import ProjectSupplementaryInfo from '../new-supplementary-info/project-supplementary-info';

export default ({
    setMapEl,
    data,
    inspectionsEl,
    title,
    propertyOrProjectAddress,
    displayAddress,
    getDirectionsTracker,
    contactAgentEl,
    inspectionTimesEl,
    setInspectionTimesEl,
}: ViewProps) => {
    return (
        <div className="project-profile-display-map" ref={setMapEl}>
            <SectionHeading className="display-map-title">{title}</SectionHeading>
            <Map data={data} />
            <ProjectSupplementaryInfo
                projectAddress={propertyOrProjectAddress}
                displayAddress={displayAddress}
                getDirectionsTracker={getDirectionsTracker}
                contactAgentEl={contactAgentEl}
                inspectionTimesEl={inspectionTimesEl}
                setInspectionTimesEl={setInspectionTimesEl}
                data={data}
                inspectionsEl={inspectionsEl}
            />
        </div>
    );
};
