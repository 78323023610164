/* @flow */
import type { SelectOptionType } from './View';

export const PAGE_INDEX = {
    PAGE_ONE: 0,
    PAGE_TWO: 1,
};

export const bedroomOptions: Array<SelectOptionType> = ['Any', '1+', '2+', '3+', '4+', '5+'].map((item) => ({
    label: item,
    value: item,
}));

export const landSizeOptions: Array<SelectOptionType> = ['Any', '200m²', '300m²', '400m²', '500m²'].map((item) => ({
    label: item,
    value: item,
}));

export const budgetOptions: Array<SelectOptionType> = [
    'Any',
    'Under $250,000',
    '$250,000 - $400,000',
    '$400,000 - $550,000',
    '$550,000 - $700,000',
    '$700,000 - $850,000',
    '$850,000 - $1,000,000',
    '$1,000,000 - $1,150,000',
    '$1,150,000 - $1,300,000',
    '$1,300,000 - $1,500,000',
    '$1,500,000 - $2,000,000',
    'Over $2,000,000',
    'Rather not say',
].map((item) => ({
    label: item,
    value: item,
}));

export const buyTimePeriod = ['1 - 3 months', '3 - 6 months', '6+ months', 'Rather not say'];
