/* @flow */
import React from 'react';
import styled from 'styled-components';
import { Alert, Divider, Link, paletteHelper, spacingHelper, typographyHelper } from '@rea-group/construct-kit-core';
import { FeatureToggle } from '../../../../toggles';

export const StyledDivider = styled(Divider)`
    margin: ${spacingHelper('large')} 0;
`;
const EventTipContainer = styled(Alert)`
    margin-bottom: ${spacingHelper('medium')};

    a,
    a:hover {
        ${typographyHelper('body01')};
        color: ${paletteHelper('linkPrimary')};
    }
`;

// Note: when deleting the Project Lifecycle feature toggle, make sure
// this component gets deleted, as well as all usage of the component
export const EventTip = ({ projectPath }: { projectPath: string }) => (
    <FeatureToggle toggleName="projectLifecycle">
        {(isToggledOn) => {
            return isToggledOn ? null : (
                <EventTipContainer>
                    Contact the agent to confirm construction progress.{' '}
                    <Link href={projectPath} target="_blank" variant="inline">
                        The project page
                    </Link>{' '}
                    may also show an estimated completion date.
                </EventTipContainer>
            );
        }}
    </FeatureToggle>
);
