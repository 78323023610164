/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { ProjectProfileDataType, AdaptedListingData, AdaptedData } from './types';
import type { ProjectProfileMedia } from '../../../models/lexa/listingMediaType';
import ProjectDocDownload from './ProjectDocDownload.graphql';

type RequiredData = {
    id: string,
    name: string,
    media: ProjectProfileMedia,
};

const dataAdapter = ({ id, name, media }: RequiredData): ?AdaptedListingData => {
    if (!media.enquiryDocuments) return null;
    return {
        listingId: id,
        childListingId: '',
        projectName: name,
        documentList: media.enquiryDocuments.items,
        requestDocsUrl: media.enquiryDocuments.url.href,
    };
};

const getListingData = compose(dataAdapter, getFragmentData(ProjectDocDownload), (data) => data.getListing());
const getEventTrackerData = (data) => ({
    onDocDownload: data.getEventTracker().docDownloadRequest,
    trackDocumentClick: () => data.getEventTracker().requestProjectInfoClick('project_doc_download_name_click'),
});

export default (data: ProjectProfileDataType): ?AdaptedData => {
    const listingData = getListingData(data);
    return listingData
        ? {
              ...listingData,
              ...getEventTrackerData(data),
          }
        : null;
};
