/* @flow */

import HttpClient from '../../../../util/http-client/httpClient';
import { IS_PRODUCTION } from '../../../../config';

export type DocDownloadService = (path: string, data: Object) => Promise<void>;

const docDownloadService: DocDownloadService = (path, data) => {
    const httpCall = HttpClient.post(path).withJsonBody(data).withAuthentication();

    if (!IS_PRODUCTION) {
        /* eslint-disable no-console */
        console.log(httpCall.asRequest());
        /* eslint-enable no-console */
        return Promise.resolve();
    }

    return httpCall.asPromise();
};

export default docDownloadService;
