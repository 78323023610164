/* @flow */
import getFragmentData from '../../../../util/getFragmentData';
import { compose3 as compose } from '../../../../util/functional/compose';
import MultiPageFormViewConfig from './MultiPageFormViewConfig.graphql';
import type { GetEventTracker, GetListing, LexaListing } from '../../../../models/lexa';
import type { EmailLeadToAgentInteractionTracker } from '../../../../client/tracking/event/types';
import type { Channel } from '../../../../util/ChannelContext';

type AdaptedProps = {
    bedroomConfigurationQuestion: boolean,
    landSizeQuestion: boolean,
    shouldRenderAgentContactForm: boolean,
    preEnquiryLogin: boolean,
};

const dataAdapter =
    (channel: Channel) =>
    ({ viewConfiguration, listingCompany }): AdaptedProps => ({
        shouldRenderAgentContactForm: !(listingCompany === null && channel === 'sold'),
        bedroomConfigurationQuestion: viewConfiguration.details.enquiryForm.bedroomConfigurationQuestion,
        landSizeQuestion: viewConfiguration.details.enquiryForm.landSizeQuestion,
        preEnquiryLogin: viewConfiguration.details.enquiryForm.preEnquiryLogin,
    });

export default (channel: Channel) =>
    compose<GetListing, LexaListing, LexaListing, AdaptedProps>(
        dataAdapter(channel),
        getFragmentData(MultiPageFormViewConfig),
        (data) => data.getListing()
    );

export const getEmailLeadToAgentInteractionTracker = (data: GetEventTracker): EmailLeadToAgentInteractionTracker =>
    data.getEventTracker().emailLeadToAgentInteraction;
