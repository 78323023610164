/* @flow */
import ScrollableRequestInfoKitButton from './scrollable-request-info-kit-button';
import React from 'react';
import type { ViewProps } from './types';

const View = ({ projectName, docDownloadEl }: ViewProps) => {
    return (
        <div className="request-project-info-kit-panel">
            <div className="request-project-info-kit-panel__info">
                <p className="request-project-info-kit-panel__info-title">Want to know more?</p>
                <p className="request-project-info-kit-panel__info-body">
                    Receive the complete {projectName} information kit
                </p>
            </div>
            <ScrollableRequestInfoKitButton docDownloadEl={docDownloadEl} />
        </div>
    );
};

export default View;
