/* @flow */

import React from 'react';
import type { ViewProps } from './types';
import { DocumentMd } from '@rea-group/construct-kit-core';
import SectionHeading from '../section-heading';
import { ResponsiveLinkButton } from '../../buttons';

const DividedStatementOfInformation = ({ href, onClick }: ViewProps) => {
    if (!href) return null;

    return (
        <div>
            <SectionHeading>Agent price guide</SectionHeading>
            <ResponsiveLinkButton
                icon={<DocumentMd />}
                target="_blank"
                rel="noopener noreferrer"
                href={href}
                onClick={onClick}
            >
                Statement of information
            </ResponsiveLinkButton>
        </div>
    );
};

export default DividedStatementOfInformation;
