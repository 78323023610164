/* @flow */
import React, { useContext } from 'react';
import EventsList from '../events-list';
import ChannelContext from '../../../../util/ChannelContext';
import ErrorBoundaryNewRelicReporter from '../../../error-boundary-new-relic-reporter';
import { createBrowserUser } from '@realestate.com.au/user';
import adaptDisplaySuiteData from './adaptDisplaySuiteData';
import { ContactAgentLink } from '../events-list/ContactAgentLink';
import type { DisplaySuiteData } from '../../project-display-map/new-supplementary-info/types';
import type { User } from '@realestate.com.au/user';

type DisplaySuiteProps = {|
    data: DisplaySuiteData,
    contactAgentEl: () => ?Element,
    getUser?: () => User,
    shouldHideTitle?: boolean,
|};

const NoInspectionsContent = ({ viewEmailAgentTracker, contactAgentEl, displayLocationTitle }) => {
    return (
        <p>
            There are no upcoming open hours for this {displayLocationTitle}. &nbsp;
            <ContactAgentLink contactAgentEl={contactAgentEl} onClick={viewEmailAgentTracker} />
            &nbsp;to arrange an appointment.
        </p>
    );
};

export const DisplaySuiteEvents = ({
    data,
    contactAgentEl,
    getUser = createBrowserUser,
    shouldHideTitle = false,
}: DisplaySuiteProps) => {
    const channel = useContext(ChannelContext);
    const displayLocationTitle = data.listing.displayLocation ? data.listing.displayLocation.title : 'display suite';
    const {
        title,
        inspectionDescription,
        listingId,
        templatedUrl,
        registrationsEnabled,
        openTimes,
        generalFeatures,
        propertySizes,
    } = adaptDisplaySuiteData(channel, shouldHideTitle)(data);
    const viewEmailAgentTracker = () => {
        return data.eventTracker.viewEmailAgentForm('contact form');
    };

    return (
        <ErrorBoundaryNewRelicReporter component="DisplaySuite">
            <EventsList
                generalFeatures={generalFeatures}
                propertySizes={propertySizes}
                eventTracker={data.eventTracker}
                title={title}
                inspectionDescription={inspectionDescription}
                templatedUrl={templatedUrl}
                inspections={openTimes}
                hasIreIntegration={false}
                contactAgentEl={contactAgentEl}
                viewEmailAgentTracker={viewEmailAgentTracker}
                listingId={listingId}
                getUser={getUser}
                channel={channel}
                showIreRegisterButton={false}
                registrationsEnabled={registrationsEnabled}
                noInspectionsContent={
                    <NoInspectionsContent
                        viewEmailAgentTracker={viewEmailAgentTracker}
                        contactAgentEl={contactAgentEl}
                        displayLocationTitle={displayLocationTitle}
                    />
                }
            />
        </ErrorBoundaryNewRelicReporter>
    );
};

export default DisplaySuiteEvents;
