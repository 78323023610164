/* @flow */
import React from 'react';
import './styles.scss';
import RequestDocsForm from './request-docs-form';
import ConfirmationModal from './confirmation-modal';
import type { ViewProps } from './types';

const Document = ({ name, onClick }) => {
    return (
        <li className="project-doc-download__item" onClick={onClick}>
            <span className="project-doc-download__doc-name">{name}</span>
        </li>
    );
};

const DOC_TEXT = "Leave your details below and we'll send you the following:";

export default ({
    projectName,
    childListingId,
    listingId,
    documentList,
    requestDocsUrl,
    consumerName,
    shouldShowConfirmationModal,
    showConfirmationModal,
    closeConfirmationModal,
    onDocDownload,
    handleDocumentClick,
    setFirstInputEl,
    setDocDownloadEl,
}: ViewProps) => {
    return (
        <div className="project-doc-download" ref={setDocDownloadEl}>
            <p className="project-doc-download__title">Receive the complete {projectName} information kit</p>
            <p className="project-doc-download__subtitle">{DOC_TEXT}</p>
            <ul className="project-doc-download__list">
                {documentList.map((documentItem, index) => (
                    <Document name={documentItem.name} key={index} onClick={handleDocumentClick} />
                ))}
            </ul>
            <RequestDocsForm
                childListingId={childListingId}
                listingId={listingId}
                requestDocsUrl={requestDocsUrl}
                onSuccess={showConfirmationModal}
                onDocDownload={onDocDownload}
                setFirstInputEl={setFirstInputEl}
            />
            {shouldShowConfirmationModal && (
                <ConfirmationModal onClose={closeConfirmationModal} consumerName={consumerName} />
            )}
        </div>
    );
};
