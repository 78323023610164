/* @flow */
import React, { type Node } from 'react';
import capitalize from 'lodash/capitalize';
import { compose } from '../../../../../util/functional/compose';
import type { AddressType } from '../types';
import DisplaySuiteEvents from '../../../calendar-events/display-suite-events';
import type { PersonalisedInspection, ProjectDetailsGetters, ProjectProfile } from '../../../../../models/lexa';
import getFragmentData from '../../../../../util/getFragmentData';
import ProjectDisplaySuites from './ProjectDisplaySuites.graphql';
import EventListWithAddress from '../sharedComponents/event-list-with-address';
import SectionHeading from '../../../section-heading';
import { StyledDivider } from '../sharedComponents';
import { FeatureToggle } from '../../../../toggles';

type ProjectSupplementaryInfoProps = {
    data: ProjectDetailsGetters,
    inspectionsEl: Node,
    contactAgentEl: () => ?Element,
    inspectionTimesEl: () => ?Element,
    setInspectionTimesEl: (?Element) => void,
    displayAddress: ?AddressType,
    projectAddress: AddressType,
    getDirectionsTracker: () => void,
};

type LexaDisplaySuiteData = {|
    listing: ProjectProfile,
    displaySuiteInspections: ?Array<PersonalisedInspection>,
|};

const adaptLexaData = compose<ProjectDetailsGetters, LexaDisplaySuiteData, LexaDisplaySuiteData>(
    getFragmentData(ProjectDisplaySuites),
    (data: ProjectDetailsGetters) => ({
        listing: data.getListing(),
        displaySuiteInspections: data.getDisplaySuiteInspections(),
    })
);

export default ({
    data,
    inspectionsEl,
    contactAgentEl,
    inspectionTimesEl,
    setInspectionTimesEl,
    displayAddress,
    projectAddress,
    getDirectionsTracker,
}: ProjectSupplementaryInfoProps) => {
    const { displaySuiteInspections, listing } = adaptLexaData(data);
    const displayLocation = listing.displayLocation;

    return (
        <EventListWithAddress
            propertyOrProjectAddress={projectAddress}
            displayAddress={((displayAddress: any): AddressType)}
            getDirectionsTracker={getDirectionsTracker}
            eventListEl={inspectionTimesEl}
            setEventListEl={setInspectionTimesEl}
        >
            <FeatureToggle toggleName={'newEvents'}>
                {(isEventsToggleOn) =>
                    isEventsToggleOn ? (
                        <React.Fragment>
                            <StyledDivider />
                            {inspectionsEl}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {displaySuiteInspections && displayLocation && (
                                <React.Fragment>
                                    <StyledDivider />
                                    <SectionHeading>{capitalize(`${displayLocation.title} open hours`)}</SectionHeading>
                                    <DisplaySuiteEvents
                                        contactAgentEl={contactAgentEl}
                                        data={{
                                            listing,
                                            displaySuiteInspections,
                                            eventTracker: data.getEventTracker(),
                                        }}
                                        shouldHideTitle={true}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                }
            </FeatureToggle>
        </EventListWithAddress>
    );
};
