/* @flow */
import React, { Component } from 'react';
import docDownloadService from './service';
import View from './View';
import { enquiredService } from '../../../../services/my-items';
import { createBrowserUser } from '@realestate.com.au/user';

type Props = {
    listingId: string,
    childListingId: string,
    requestDocsUrl: string,
    onSuccess: (consumerName: string) => void,
    onDocDownload: () => void,
    setFirstInputEl: (?HTMLElement) => void,
};

type State = {
    name: string,
    email: string,
    mobile: string,
    consumerComment: string,
    shouldDisableSubmitButton: boolean,
    showNetworkErrorMessage: boolean,
    validationError: ValidationError,
};

type ValidationError = {
    isNameBlank: boolean,
    doesNameContainUrl: boolean,
    isEmailBlank: boolean,
    isEmailInvalid: boolean,
    isPhoneInvalid: boolean,
};

const isBlank = (s) => typeof s === 'string' && s.trim() === '';
const isValidPhone = (s) => typeof s === 'string' && /^[+]?[\s\d]*[(]?\d+[)]?[-\s\d]+$/.test(s);
const containsBadUrl = (s) => {
    const sWithoutReaUrls = s.replace(/([\w.]*\.)?(realestate|realcommercial)\.com\.au/, '');
    return /[\w-]\.[\w-]/.test(sWithoutReaUrls);
};
const isValidEmail = (s) => typeof s === 'string' && /@.*\./.test(s);

class RequestDocsFormContainer extends Component<Props, State> {
    state = {
        name: '',
        email: '',
        mobile: '',
        consumerComment: '',
        shouldDisableSubmitButton: false,
        showNetworkErrorMessage: false,
        validationError: {
            isNameBlank: false,
            doesNameContainUrl: false,
            isEmailBlank: false,
            isEmailInvalid: false,
            isPhoneInvalid: false,
        },
    };

    handleNameChange = (e: SyntheticInputEvent<>) => {
        this.setState({
            name: e.target.value,
        });
    };

    handleEmailChange = (e: SyntheticInputEvent<>) => {
        this.setState({
            email: e.target.value,
        });
    };

    handleMobileChange = (e: SyntheticInputEvent<>) => {
        this.setState({
            mobile: e.target.value,
        });
    };

    handleConsumerCommentChange = (e: SyntheticInputEvent<>) => {
        this.setState({
            consumerComment: e.target.value,
        });
    };

    handleSubmissionFailure = (noProps: Error) => {
        this.setState({ showNetworkErrorMessage: true });
    };

    onFormSubmitting = () => {
        this.setState({ shouldDisableSubmitButton: true });
    };

    onFormSubmitted = () => {
        this.setState({ shouldDisableSubmitButton: false });
    };

    resetForm = () => {
        this.setState({
            name: '',
            email: '',
            mobile: '',
            consumerComment: '',
        });
    };

    isFormValid = () => {
        const validationError = this.state.validationError;
        const values = Object.keys(validationError).map((e) => validationError[e]);
        return values.every((error) => !error);
    };

    handleSubmit = () => {
        const { name, email, mobile, consumerComment } = this.state;
        const { listingId, childListingId, requestDocsUrl, onSuccess, onDocDownload } = this.props;
        const user = createBrowserUser();
        const data = {
            name: name,
            fromAddress: email,
            fromPhone: mobile,
            consumerComment: consumerComment,
            childListingId,
            myReaId: user.consumerId,
        };
        this.setState(
            {
                validationError: {
                    isNameBlank: isBlank(name),
                    doesNameContainUrl: !isBlank(name) && containsBadUrl(name),
                    isEmailBlank: isBlank(email),
                    isEmailInvalid: !isBlank(email) && !isValidEmail(email),
                    isPhoneInvalid: !isBlank(mobile) && !isValidPhone(mobile),
                },
                showNetworkErrorMessage: false,
            },
            () => {
                if (this.isFormValid()) {
                    this.onFormSubmitting();
                    onDocDownload();
                    return docDownloadService(requestDocsUrl, data)
                        .then(this.resetForm)
                        .then((value) => {
                            onSuccess(name);
                            if (user.isSignedIn) {
                                const service = enquiredService();
                                service.add(listingId);
                            }
                            return value;
                        })
                        .catch(this.handleSubmissionFailure)
                        .finally(this.onFormSubmitted);
                }
            }
        );
    };

    render() {
        const { setFirstInputEl } = this.props;
        return (
            <View
                {...this.state}
                setFirstInputEl={setFirstInputEl}
                handleNameChange={this.handleNameChange}
                handleEmailChange={this.handleEmailChange}
                handleMobileChange={this.handleMobileChange}
                handleConsumerCommentChange={this.handleConsumerCommentChange}
                handleSubmit={this.handleSubmit}
            />
        );
    }
}

export default RequestDocsFormContainer;
