/* @flow */
import React from 'react';
import './styles.scss';
import adaptProjectProfileData from './adaptProjectProfileData';
import adaptResidentialListingData from './adaptResidentialListingData';
import type { ProjectProfileProps, BuyResidentialListingProps } from './types';
import Container from './Container';

export const RequestProjectInfoKitPanel = ({ data, docDownloadEl, firstDocDownloadInputEl }: ProjectProfileProps) => {
    const adaptedData = adaptProjectProfileData(data);
    if (adaptedData) {
        return (
            <Container
                {...adaptedData}
                docDownloadEl={docDownloadEl}
                firstDocDownloadInputEl={firstDocDownloadInputEl}
            />
        );
    }
    return null;
};

export const ChildListingRequestProjectInfoKitPanel = ({
    data,
    docDownloadEl,
    firstDocDownloadInputEl,
}: BuyResidentialListingProps) => {
    const adaptedData = adaptResidentialListingData(data);
    if (adaptedData) {
        return (
            <Container
                {...adaptedData}
                docDownloadEl={docDownloadEl}
                firstDocDownloadInputEl={firstDocDownloadInputEl}
            />
        );
    }
    return null;
};
