/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import { childListingAdapter, getEventTrackerData } from '../adaptLexaData';
import type { BuyResidentialListingDetailsGetters } from '../../../../models/lexa';
import ChildListingDisplayMap from './ChildListingDisplayMap.graphql';

const getListingData = compose(
    childListingAdapter,
    getFragmentData(ChildListingDisplayMap),
    (data: BuyResidentialListingDetailsGetters) => ({
        listing: data.getListing(),
        inspections: data.getInspections(),
        parentDisplaySuiteInspections: data.getParentDisplaySuiteInspections(),
    })
);

export default (data: BuyResidentialListingDetailsGetters) => {
    const listingData = getListingData(data);
    return (
        listingData && {
            ...listingData,
            ...getEventTrackerData(data),
        }
    );
};
