/* @flow */

import { compose3 as compose } from '../../../../util/functional/compose';
import getFragmentData from '../../../../util/getFragmentData';
import { getEventTrackerData, projectAdapter } from '../adaptLexaData';
import type { AdaptedListingData, ProjectProfileDataType } from './types';
import type { GetProjectProfile, ProjectProfile } from '../../../../models/lexa';
import ProjectDisplayMap from './ProjectDisplayMap.graphql';

const getListingData = compose<GetProjectProfile, ProjectProfile, ProjectProfile, ?AdaptedListingData>(
    projectAdapter,
    getFragmentData(ProjectDisplayMap),
    (data) => data.getListing()
);

export default (data: ProjectProfileDataType) => {
    const listingData = getListingData(data);
    return (
        listingData && {
            ...listingData,
            ...getEventTrackerData(data),
        }
    );
};
