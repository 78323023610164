import styled, { css } from 'styled-components';
import { ResponsiveButton } from '../../../buttons';
import { paletteHelper, P, spacingHelper } from '@rea-group/construct-kit-core';

const sharedStyle = css`
    vertical-align: top;
    ${({ theme }) => theme.breakpoints.medium} {
        display: inline-block;
        width: 49%;
    }
`;

export const StyledRequestDocsEmail = styled.div`
    ${sharedStyle}
    ${({ theme }) => theme.breakpoints.medium} {
        margin-right: 2%;
    }
`;

export const StyledRequestDocsMobile = styled.div`
    ${sharedStyle}
`;

export const StyledRequestDocsHiddenElement = styled.div`
    display: none;
`;

export const StyledRequestDocsErrorMessage = styled(P).attrs({ variant: 'body02' })`
    margin-bottom: 0;
    color: ${paletteHelper('textWarning')};
`;

export const StyledRequestDocsFooter = styled.div`
    position: relative;
    margin-top: ${spacingHelper('extraLarge')};
    ${({ theme }) => theme.breakpoints.medium} {
        border-top: 1px solid ${paletteHelper('borderSecondary')};
    }
`;

export const SendRequestButton = styled(ResponsiveButton)`
    ${({ theme }) => theme.breakpoints.medium} {
        position: absolute;
        top: 26px;
        right: 0;
    }
`;
