/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { ProjectProfileMedia, GetProjectProfile, ProjectProfile } from '../../models/lexa/index';
import ProjectBanner from './ProjectBanner.graphql';

type RequiredData = {
    media: ProjectProfileMedia,
};

type AdaptedData = {
    templatedUrl: string,
};

const dataAdapter = ({ media }: RequiredData): ?AdaptedData => {
    if (media.banner) {
        return {
            templatedUrl: media.banner.templatedUrl,
        };
    }
    return null;
};

export default compose<GetProjectProfile, ProjectProfile, RequiredData, ?AdaptedData>(
    dataAdapter,
    getFragmentData(ProjectBanner),
    (data) => data.getListing()
);
