/* @flow */
import type { MultipageContactAgentFormFields } from './View';

const isRatherNotSay = (s: string) => s === 'Rather not say';

const getPropertyMessageArray = (
    formState: MultipageContactAgentFormFields,
    isForTrackMessage?: boolean
): Array<string> => {
    const { consumerType, budget, bedroom, buyTime, landSize } = formState;
    const consumerTypeMessage = consumerType?.value || '';
    const landSizeMessage = landSize?.value ? `- Minimum Land Size: ${landSize.value}` : '';
    const bedroomMessage = bedroom?.value ? `- Number of Bedrooms: ${bedroom.value}` : '';
    let budgetMessage = budget.value ? `- My Budget: ${budget.value}` : '';
    let buyTimeMessage = buyTime ? `- When I am looking to buy: ${buyTime}` : '';

    if (isForTrackMessage) {
        return [consumerTypeMessage, landSizeMessage, bedroomMessage, budgetMessage, buyTimeMessage].filter(Boolean);
    }
    budgetMessage = isRatherNotSay(budget.value) ? '' : budgetMessage;
    buyTimeMessage = isRatherNotSay(buyTime) ? '' : buyTimeMessage;
    return [landSizeMessage, bedroomMessage, budgetMessage, buyTimeMessage].filter(Boolean);
};

export const constructAboutMeTrackMessage = (formState: MultipageContactAgentFormFields): Array<string> => {
    return getPropertyMessageArray(formState, true);
};

export const constructPropertyPreferencesMessage = (formState: MultipageContactAgentFormFields): string => {
    const lineBreak = '\n';
    const messageArray = getPropertyMessageArray(formState);
    if (messageArray.length === 0) {
        return '';
    }

    const preferenceTypesBody = messageArray.join(lineBreak);
    return `${lineBreak}${lineBreak}My property preferences:${lineBreak}${preferenceTypesBody}`;
};
