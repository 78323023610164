/* @flow */
import type { Node } from 'react';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Label, spacingHelper } from '@rea-group/construct-kit-core';
import { scrollTo } from '../../../../util/DOMUtils';
import { PageOne } from './page-one';
import { PageTwo } from './page-two';
import { PAGE_INDEX } from './constants';
import StickyBrandingContext from '../../StickyBrandingContext';
import type { GetBuyResidentialListing, GetProjectProfile } from '../../../../models/lexa';
import type { AgentContactService, ConsumerRequestsContext, OnSuccess } from '../contact-agent-form/types';
import type { User } from '@realestate.com.au/user';
import { createBrowserUser } from '@realestate.com.au/user';
import type { PrefillData } from '../contact-agent-form/enquiry-prefill';
import { consumerTypeInputOverPrefill } from '../contact-agent-form/enquiry-consumer';
import appLogger from '../../../../util/logging/appLogger';
import { isBlank } from '../contact-agent-form/validateContactAgentForm';
import { preapprovalMessage } from '../contact-agent-form/enquiry-preapproval';
import { constructAboutMeTrackMessage, constructPropertyPreferencesMessage } from './formHelpers';
import { type Channel } from '../../../../models/Channel';
import type { EventEmailAgentData } from '../../../../client/tracking/event/types';
import type { MultiPageFormTrackers } from './types';
import AuthToEnquire from '../auth-to-enquire';
import { FakeEnquiryForm } from '../contact-agent-form/ViewWithLogin';
import HttpClientError from '../../../../util/http-client/HttpClientError';
import { HttpStatus } from '../../../../util/http-client/HttpStatus';

export type MultipageFormViewConfig = {
    landSizeQuestion: boolean,
    bedroomConfigurationQuestion: boolean,
    preEnquiryLogin: boolean,
};

type MultipageFormViewConfiguration = { multipageFormViewConfiguration: MultipageFormViewConfig };

export type Props = {
    agentAndListingInfo: Node,
    data: GetBuyResidentialListing | GetProjectProfile,
    listingId: string,
    url: string,
    agentContactService: AgentContactService,
    getUser?: () => User,
    onSuccess: OnSuccess,
    multiPageFormTrackers: MultiPageFormTrackers,
    channel: Channel,
} & PrefillProps;

type PrefillProps = {
    prefillData?: PrefillData,
    savePrefillData?: (PrefillData) => void,
};

export type SelectOptionType = {|
    label: string,
    value: string,
|};

export type ChangeFormValueType = SelectOptionType | string;

export type MultipageContactAgentFormFields = {
    consumerType: ?SelectOptionType,
    consumerMessage: string,
    name: string,
    email: string,
    phoneNumber: string,
    postcode: string,
    preapproval: string,
    bedroom: SelectOptionType,
    budget: SelectOptionType,
    buyTime: string,
    landSize: SelectOptionType,
};

export type UserContactInfoType = {|
    name: string,
    email: string,
    phoneNumber: string,
    postcode: string,
    preapproval: string,
|};

type PropsWithConsumerRequestsContext = Props & {
    consumerRequestsContext: ConsumerRequestsContext,
} & MultipageFormViewConfiguration;

type FilteredFormState = MultipageContactAgentFormFields & { bedroom: ?SelectOptionType, landSize: ?SelectOptionType };

const Form = styled.form`
    line-height: ${spacingHelper('large')};
`;

const emptySelectOption = {
    label: '',
    value: '',
};
const defaultFormState: MultipageContactAgentFormFields = {
    consumerType: null,
    consumerMessage: '',
    name: '',
    email: '',
    phoneNumber: '',
    postcode: '',
    preapproval: '',
    bedroom: emptySelectOption,
    budget: emptySelectOption,
    buyTime: '',
    landSize: emptySelectOption,
};

const initFormState = (channel: Channel, prefillData?: PrefillData): MultipageContactAgentFormFields => {
    if (prefillData && Object.keys(prefillData).length > 0) {
        const { name, email, phoneNumber, postcode, preapproval, consumerType, bedroom, budget, buyTime, landSize } =
            prefillData;
        return {
            ...defaultFormState,
            name,
            email,
            phoneNumber,
            postcode,
            preapproval,
            bedroom: bedroom || emptySelectOption,
            budget: budget || emptySelectOption,
            buyTime,
            landSize: landSize || emptySelectOption,
            consumerType: { ...consumerTypeInputOverPrefill(channel, null, consumerType) },
        };
    }

    return defaultFormState;
};

const generateFilteredFormState = (
    formState: MultipageContactAgentFormFields,
    multipageFormViewConfiguration: MultipageFormViewConfig
): FilteredFormState => {
    const {
        name,
        email,
        phoneNumber,
        postcode,
        consumerType,
        budget,
        consumerMessage,
        preapproval,
        buyTime,
        bedroom,
        landSize,
    } = formState;

    return {
        name,
        email,
        phoneNumber,
        postcode,
        consumerType,
        budget,
        consumerMessage,
        preapproval,
        buyTime,
        ...(multipageFormViewConfiguration.bedroomConfigurationQuestion ? { bedroom } : {}),
        ...(multipageFormViewConfiguration.landSizeQuestion ? { landSize } : {}),
    };
};

const generateTrackingData = (
    formState: MultipageContactAgentFormFields,
    consumerRequestsContext: ConsumerRequestsContext,
    multipageFormViewConfiguration: MultipageFormViewConfig
): EventEmailAgentData => {
    const { email, phoneNumber, consumerMessage, preapproval, postcode } = formState;

    return {
        enquiry_type: consumerRequestsContext.consumerRequests,
        about_me: constructAboutMeTrackMessage(generateFilteredFormState(formState, multipageFormViewConfiguration)),
        message_provided: !isBlank(consumerMessage),
        email_provided: !isBlank(email),
        mobile_provided: !isBlank(phoneNumber),
        postcode_provided: !isBlank(postcode),
        pre_approval: preapproval === 'ratherNotSay' ? 'rather_not_say' : preapproval,
    };
};

export const ViewWithLogin = ({
    agentAndListingInfo,
    consumerRequestsContext,
    data,
    prefillData,
    savePrefillData,
    onSuccess,
    listingId,
    url,
    agentContactService,
    getUser = createBrowserUser,
    multipageFormViewConfiguration,
    channel,
    multiPageFormTrackers: { formSubmittedTracker, emailLeadToAgentInteractionTracker },
}: PropsWithConsumerRequestsContext) => {
    const { stickyBrandingOffset } = useContext(StickyBrandingContext);

    const [pageIndex, setPageIndex] = useState(PAGE_INDEX.PAGE_ONE);
    const [formState, setFormState] = useState<MultipageContactAgentFormFields>(defaultFormState);

    const [showNetworkErrorMessage, setShowNetworkErrorMessage] = useState(false);
    const [showBadWordErrorMessage, setShowBadWordErrorMessage] = useState(false);
    const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false);
    const formRef = useRef<null | HTMLFormElement>(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        setFormState(initFormState(channel, prefillData));
    }, [prefillData, channel]);

    useEffect(() => {
        setUser(getUser());
    }, [getUser]);

    const onJoinOrSignInSuccess = () => {
        setUser(getUser());
    };

    const scrollToFormTop = () => {
        const element = document.querySelector('.contact-agent-section');
        element && scrollTo(element, stickyBrandingOffset);
    };

    const onChangeForm = (formKey: string, value: ChangeFormValueType) => {
        const newForm = { ...formState };
        newForm[formKey] = value;
        setFormState(newForm);
    };

    const onChangePage = (newPageIndex: number) => (data?: UserContactInfoType) => {
        data &&
            setFormState((prev) => {
                const newFormState = { ...prev, ...data };
                if (newPageIndex === PAGE_INDEX.PAGE_TWO) {
                    const trackingData = generateTrackingData(
                        newFormState,
                        consumerRequestsContext,
                        multipageFormViewConfiguration
                    );
                    emailLeadToAgentInteractionTracker(trackingData);
                }
                return newFormState;
            });

        scrollToFormTop();
        setPageIndex(newPageIndex);
    };

    const resetNonPrefilledFields = () => {
        setFormState((prev) => ({
            ...prev,
            consumerMessage: '',
        }));

        consumerRequestsContext.resetConsumerRequests();
    };

    const resetAllFields = () => {
        setFormState(defaultFormState);
        formRef.current && formRef.current.reset();
        consumerRequestsContext.resetConsumerRequests();
    };

    const handleSubmissionFailure = (error: HttpClientError) => {
        appLogger.error('Error submitting contact agent form')(error);
        if (error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            setShowBadWordErrorMessage(true);
        } else {
            setShowNetworkErrorMessage(true);
        }
    };

    const onFormSubmitting = () => {
        setShouldDisableSubmitButton(true);
    };

    const onFormSubmitted = () => {
        setShouldDisableSubmitButton(false);
    };

    const onSubmit = (e: SyntheticEvent<>) => {
        e.preventDefault();
        setShowNetworkErrorMessage(false);
        setShowBadWordErrorMessage(false);

        const {
            name,
            email,
            phoneNumber,
            postcode,
            consumerType,
            bedroom,
            budget,
            consumerMessage,
            preapproval,
            buyTime,
            landSize,
        } = formState;

        const trackingData = generateTrackingData(formState, consumerRequestsContext, multipageFormViewConfiguration);

        const payload = {
            likeTo: consumerRequestsContext.consumerRequests,
            lookingTo: consumerType?.label || '',
            name: name,
            fromAddress: email,
            fromPhone: phoneNumber,
            postcode: postcode,
            message: `${consumerMessage}${constructPropertyPreferencesMessage(
                generateFilteredFormState(formState, multipageFormViewConfiguration)
            )}${preapprovalMessage(preapproval)}\n\n`,
            myReaId: user && user.consumerId,
        };

        onFormSubmitting();
        formSubmittedTracker(trackingData);

        return agentContactService(url, payload)
            .then((value) => {
                resetNonPrefilledFields();
                const prefillDataToSave: PrefillData = {
                    name,
                    email,
                    preapproval,
                    phoneNumber,
                    postcode,
                    bedroom,
                    budget,
                    buyTime,
                    landSize,
                    consumerType: consumerType ? consumerType.value : '',
                };
                onSuccess({
                    listingId,
                    consumerName: name,
                    isUserSignedIn: true,
                    preapproval,
                    savePrefillDataAfterLogin: () => {
                        savePrefillData?.(prefillDataToSave);
                    },
                    resetFormData: resetAllFields,
                });

                savePrefillData && prefillData && savePrefillData(prefillDataToSave);
                setPageIndex(PAGE_INDEX.PAGE_ONE);
                return value;
            })
            .catch(handleSubmissionFailure)
            .finally(onFormSubmitted);
    };

    if (user && user.isSignedIn) {
        return (
            <Form
                onSubmit={onSubmit}
                ref={formRef}
                autoComplete="on"
                noValidate={true}
                data-testid="multiPageContactAgentWithLogin"
            >
                {pageIndex === PAGE_INDEX.PAGE_ONE && (
                    <Fragment>
                        <Label hideLabel={true} aria-live="polite">
                            Enquiry form part 1 of 2
                        </Label>
                        <PageOne
                            data={data}
                            consumerRequestsContext={consumerRequestsContext}
                            formState={formState}
                            agentAndListingInfo={agentAndListingInfo}
                            onChangePage={onChangePage(PAGE_INDEX.PAGE_TWO)}
                            onChangeForm={onChangeForm}
                        />
                    </Fragment>
                )}
                {pageIndex === PAGE_INDEX.PAGE_TWO && (
                    <Fragment>
                        <Label hideLabel={true} aria-live="polite">
                            Enquiry form part 2 of 2
                        </Label>
                        <PageTwo
                            formState={formState}
                            onChangePage={onChangePage(PAGE_INDEX.PAGE_ONE)}
                            onChangeForm={onChangeForm}
                            showNetworkErrorMessage={showNetworkErrorMessage}
                            showBadWordErrorMessage={showBadWordErrorMessage}
                            shouldDisableSubmitButton={shouldDisableSubmitButton}
                            multipageFormViewConfiguration={multipageFormViewConfiguration}
                        />
                    </Fragment>
                )}
            </Form>
        );
    }

    return (
        <Fragment>
            {agentAndListingInfo}
            <AuthToEnquire enquiryForm={FakeEnquiryForm} onJoinOrSignInSuccess={onJoinOrSignInSuccess} />
        </Fragment>
    );
};
