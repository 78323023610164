/* @flow */

import React, { type Node } from 'react';
import styled from 'styled-components';
import ChildListingDisplaySuite from '../child-listing-display-suite';
import type { BuyResidentialListingDetailsGetters } from '../../../../../../models/lexa';
import { spacingHelper, Tab, TabBar, TabList, TabPanel, TabPanels, Tabs } from '@rea-group/construct-kit-core';
import Inspection from '../../../../calendar-events/open-for-inspection';
import Address from '../../sharedComponents/address';
import type { AddressType } from '../../types';
import { EventTip } from '../../sharedComponents';
import useScrollToElement from '../../../../useScrollToElement';
import { FeatureToggle } from '../../../../../toggles';

type EventListProps = {
    data: BuyResidentialListingDetailsGetters,
    inspectionsEl: Node,
    contactAgentEl: () => ?Element,
    projectTabTitle: string,
    displayAddress: AddressType,
    propertyAddress: AddressType,
    parentPath: string,
    getDirectionsTracker: () => void,
    eventListEl: () => ?Element,
    setEventListEl: (?Element) => void,
};

const EventListContainer = styled.div`
    margin-top: ${spacingHelper('extraSmall')};
`;

const StyledTabBar = styled(TabBar)`
    margin-bottom: ${spacingHelper('medium')};
`;

const EventListTab = ({
    data,
    inspectionsEl,
    contactAgentEl,
    displayAddress,
    propertyAddress,
    getDirectionsTracker,
    projectTabTitle,
    parentPath,
    eventListEl,
    setEventListEl,
}: EventListProps) => {
    useScrollToElement(() => window.location.search.includes('scrollTo=inspection-times'), eventListEl);
    return (
        <EventListContainer ref={setEventListEl}>
            <Tabs variant="full-width">
                <StyledTabBar>
                    <TabList buttonColor="backgroundPrimaryLow">
                        <Tab>Property inspections</Tab>
                        <Tab>{projectTabTitle}</Tab>
                    </TabList>
                </StyledTabBar>
                <TabPanels renderForSeo={true}>
                    <TabPanel>
                        <Address
                            address={propertyAddress}
                            isTabAddress={true}
                            getDirectionsTracker={getDirectionsTracker}
                        />
                        <FeatureToggle toggleName={'newEvents'}>
                            {(isEventsToggleOn) =>
                                isEventsToggleOn ? (
                                    inspectionsEl
                                ) : (
                                    <Inspection data={data} contactAgentEl={contactAgentEl} shouldHideTitle={true} />
                                )
                            }
                        </FeatureToggle>
                    </TabPanel>
                    <TabPanel>
                        <Address
                            address={displayAddress}
                            isTabAddress={true}
                            getDirectionsTracker={getDirectionsTracker}
                        />
                        <FeatureToggle toggleName={'newEvents'}>
                            {(isEventsToggleOn) =>
                                isEventsToggleOn ? (
                                    inspectionsEl
                                ) : (
                                    <ChildListingDisplaySuite data={data} contactAgentEl={contactAgentEl} />
                                )
                            }
                        </FeatureToggle>
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <EventTip projectPath={parentPath} />
        </EventListContainer>
    );
};

export default EventListTab;
