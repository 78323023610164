/* @flow */
import React from 'react';
import Container from './Container';
import adaptResidentialListingData from './adaptResidentialListingData';
import adaptProjectProfileData from './adaptProjectProfileData';

import type { BuyResidentialListingDocDownloadProps, ProjectProfileDocDownloadProps } from './types';

export const ChildListingDocDownload = ({
    data,
    setDocDownloadEl,
    setFirstDocDownloadInputEl,
    firstDocDownloadInputEl,
}: BuyResidentialListingDocDownloadProps) => {
    const adaptedData = adaptResidentialListingData(data);
    if (adaptedData) {
        return (
            <Container
                {...adaptedData}
                setDocDownloadEl={setDocDownloadEl}
                setFirstDocDownloadInputEl={setFirstDocDownloadInputEl}
                firstDocDownloadInputEl={firstDocDownloadInputEl}
            />
        );
    }
    return null;
};

export const ProjectProfileDocDownload = ({
    data,
    setDocDownloadEl,
    setFirstDocDownloadInputEl,
    firstDocDownloadInputEl,
}: ProjectProfileDocDownloadProps) => {
    const adaptedData = adaptProjectProfileData(data);
    if (adaptedData) {
        return (
            <Container
                {...adaptedData}
                setDocDownloadEl={setDocDownloadEl}
                setFirstDocDownloadInputEl={setFirstDocDownloadInputEl}
                firstDocDownloadInputEl={firstDocDownloadInputEl}
            />
        );
    }
    return null;
};
