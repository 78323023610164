/* @flow */
import React, { Fragment, useRef, useState } from 'react';
import { Button, paletteHelper, Stack, TextArea, TextInput, typographyHelper } from '@rea-group/construct-kit-core';
import type { Node } from 'react';
import EnquiryRequest from '../../contact-agent-form/enquiry-request';
import TwoColumnLayout from '../../contact-agent-form/Layout';
import EnquiryConsumer from '../../contact-agent-form/enquiry-consumer';
import EnquiryPreapproval from '../../contact-agent-form/enquiry-preapproval';
import {
    containsBadUrl,
    errorMessagesTemplate,
    validateEmail,
    validateName,
    validatePhoneNumber,
    validatePostcode,
} from '../../contact-agent-form/validateContactAgentForm';
import type { GetBuyResidentialListing, GetProjectProfile } from '../../../../../models/lexa';
import type { ConsumerRequestsContext } from '../../contact-agent-form/types';
import styled from 'styled-components';
import type { ChangeFormValueType, MultipageContactAgentFormFields, UserContactInfoType } from '../View';

type Props = {|
    agentAndListingInfo: Node,
    formState: MultipageContactAgentFormFields,
    onChangePage: (data?: UserContactInfoType) => void,
    consumerRequestsContext: ConsumerRequestsContext,
    data: GetBuyResidentialListing | GetProjectProfile,
    onChangeForm: (formKey: string, value: ChangeFormValueType) => void,
|};

type PageOneValidationErrorMessages = {
    consumerMessageError: string | typeof undefined,
    nameError: string | typeof undefined,
    emailError: string | typeof undefined,
    phoneError: string | typeof undefined,
    postcodeError: string | typeof undefined,
};

const getValueOrEmptyString = (ref: { current: null | HTMLInputElement }): string => {
    const fieldValue = ref.current?.value ?? '';
    return fieldValue.trim();
};

const RequiredLabel = styled.span`
    ${typographyHelper('body02')}
    color: ${paletteHelper('textSecondary')};
`;

export const PageOne = ({
    formState,
    agentAndListingInfo,
    onChangePage,
    consumerRequestsContext,
    onChangeForm,
}: Props) => {
    const nameRef = useRef<null | HTMLInputElement>(null);
    const emailRef = useRef<null | HTMLInputElement>(null);
    const phoneRef = useRef<null | HTMLInputElement>(null);
    const postcodeRef = useRef<null | HTMLInputElement>(null);

    const [validationErrorMessages, setValidationErrorMessages] = useState<PageOneValidationErrorMessages>({
        consumerMessageError: undefined,
        nameError: undefined,
        emailError: undefined,
        phoneError: undefined,
        postcodeError: undefined,
    });

    const handleConsumerMessageChange = (event) => {
        onChangeForm('consumerMessage', event.target.value);
    };

    const handleConsumerRequestChange = (event) => {
        if (event.target.checked) {
            consumerRequestsContext.addConsumerRequest(event.target.value);
        } else {
            consumerRequestsContext.removeConsumerRequest(event.target.value);
        }
    };

    const handlePreapprovalChange = (event) => {
        onChangeForm('preapproval', event.target.value);
    };

    const handleConsumerTypeChange = (selected: Object) => {
        onChangeForm('consumerType', selected);
    };

    const handleNameBlur = () => {
        const name = getValueOrEmptyString(nameRef);
        const newValidationErrorMessages = {
            ...validationErrorMessages,
            nameError: validateName(name),
        };
        setValidationErrorMessages(newValidationErrorMessages);
    };

    const handleEmailBlur = () => {
        const email = getValueOrEmptyString(emailRef);
        const newValidationErrorMessages = {
            ...validationErrorMessages,
            emailError: validateEmail(email),
        };
        setValidationErrorMessages(newValidationErrorMessages);
    };

    const handlePhoneBlur = () => {
        const phone = getValueOrEmptyString(phoneRef);
        const newValidationErrorMessages = {
            ...validationErrorMessages,
            phoneError: validatePhoneNumber(phone),
        };
        setValidationErrorMessages(newValidationErrorMessages);
    };

    const handlePostcodeBlur = () => {
        const postcode = getValueOrEmptyString(postcodeRef);
        const newValidationErrorMessages = {
            ...validationErrorMessages,
            postcodeError: validatePostcode(postcode),
        };
        setValidationErrorMessages(newValidationErrorMessages);
    };

    const isFormValid = (validationErrors: PageOneValidationErrorMessages) => {
        const values = Object.keys(validationErrors).map((e) => validationErrors[e]);
        return values.every((error) => error === '');
    };

    const onClickNext = (event) => {
        event.preventDefault();

        const name = getValueOrEmptyString(nameRef);
        const email = getValueOrEmptyString(emailRef);
        const phoneNumber = getValueOrEmptyString(phoneRef);
        const postcode = getValueOrEmptyString(postcodeRef);
        const { consumerMessage, preapproval } = formState;

        const validationErrors = {
            consumerMessageError: containsBadUrl(consumerMessage) ? errorMessagesTemplate.containsDots : '',
            nameError: validateName(name),
            emailError: validateEmail(email),
            phoneError: validatePhoneNumber(phoneNumber),
            postcodeError: validatePostcode(postcode),
        };

        if (isFormValid(validationErrors)) {
            onChangePage({
                name,
                email,
                phoneNumber,
                postcode,
                preapproval,
            });
        } else {
            setValidationErrorMessages(validationErrors);
        }
    };
    return (
        <Fragment>
            {agentAndListingInfo}
            <Stack inset="medium 0" gap="medium">
                <EnquiryRequest
                    checkedValues={consumerRequestsContext.consumerRequests}
                    handleChange={handleConsumerRequestChange}
                />
                <TextArea
                    label="Message"
                    id="contactAgentConsumerMessage"
                    value={formState.consumerMessage}
                    onChange={handleConsumerMessageChange}
                    errorMessage={validationErrorMessages.consumerMessageError}
                />
                <TwoColumnLayout variant="large" gap="medium">
                    <TextInput
                        id="contactAgentEnquiryName"
                        label={
                            <React.Fragment>
                                Name
                                <RequiredLabel> (required)</RequiredLabel>
                            </React.Fragment>
                        }
                        onBlur={handleNameBlur}
                        name="name"
                        autoComplete="name"
                        errorMessage={validationErrorMessages.nameError}
                        iconLeft={false}
                        ref={nameRef}
                        defaultValue={formState.name}
                    />
                    <TextInput
                        id="contactAgentEnquiryEmail"
                        label={
                            <React.Fragment>
                                Email address
                                <RequiredLabel> (required)</RequiredLabel>
                            </React.Fragment>
                        }
                        type="email"
                        onBlur={handleEmailBlur}
                        name="email"
                        autoComplete="email"
                        errorMessage={validationErrorMessages.emailError}
                        iconLeft={false}
                        ref={emailRef}
                        defaultValue={formState.email}
                    />
                    <TextInput
                        id="contactAgentEnquiryMobile"
                        label="Phone number"
                        type="tel"
                        onBlur={handlePhoneBlur}
                        name="mobile"
                        autoComplete="tel"
                        errorMessage={validationErrorMessages.phoneError}
                        iconLeft={false}
                        ref={phoneRef}
                        defaultValue={formState.phoneNumber}
                    />
                    <TextInput
                        id="contactAgentEnquiryPostcode"
                        label="Postcode"
                        onBlur={handlePostcodeBlur}
                        name="postcode"
                        autoComplete="postcode"
                        errorMessage={validationErrorMessages.postcodeError}
                        iconLeft={false}
                        ref={postcodeRef}
                        defaultValue={formState.postcode}
                    />
                    <EnquiryConsumer selectedOption={formState.consumerType} handleChange={handleConsumerTypeChange} />
                </TwoColumnLayout>
                <EnquiryPreapproval selectedOption={formState.preapproval} handleChange={handlePreapprovalChange} />
            </Stack>
            <Button type="button" onClick={onClickNext} id="contactAgentEnquiryNext" fullWidth={true}>
                Next step: property preferences
            </Button>
        </Fragment>
    );
};
