/* @flow */

import React from 'react';
import { Link } from '@rea-group/construct-kit-core';
import type { ViewProps } from './types';
import { DocumentMd, spacingHelper, typographyHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

const StyledLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    ${typographyHelper('subtitle01')}
`;

const StyledIcon = styled(DocumentMd)`
    margin-right: ${spacingHelper('extraSmall')};
`;

const StatementOfInformation = ({
    href,
    className,
    onClick,
    displayText = 'View statement of information',
}: ViewProps) => {
    if (!href) return null;

    return (
        <div className={`statement-of-information ${className ? className : ''}`}>
            <StyledLink href={href} target="_blank" rel="noopener noreferrer" onClick={onClick}>
                <StyledIcon />
                {displayText}
            </StyledLink>
        </div>
    );
};

export default StatementOfInformation;
