/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetBuyResidentialListing, BuyResidentialListing, ProjectProfile } from '../../models/lexa';
import ChildListingParentProjectBanner from './ChildListingParentProjectBanner.graphql';

type RequiredData = {
    parent: ?ProjectProfile,
};

type AdaptedData = {
    templatedUrl: string,
};

const dataAdapter = ({ parent }: RequiredData): ?AdaptedData => {
    if (parent && parent.media.banner) {
        return {
            templatedUrl: parent.media.banner.templatedUrl,
        };
    }
    return null;
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, RequiredData, ?AdaptedData>(
    dataAdapter,
    getFragmentData(ChildListingParentProjectBanner),
    (data) => data.getListing()
);
