/* @flow */
import React from 'react';
import { ChildListingMap } from '../../map';
import type { ViewProps } from './types';
import SectionHeading from '../../section-heading';
import ChildListingSupplementaryInfo from '../new-supplementary-info/child-listing-supplementary-info';

export default ({
    data,
    inspectionsEl,
    title,
    propertyOrProjectAddress,
    displayAddress,
    getDirectionsTracker,
    contactAgentEl,
    inspectionTimesEl,
    setInspectionTimesEl,
}: ViewProps) => {
    return (
        <div className="child-listing-project-display-map">
            <SectionHeading className="display-map-title">{title}</SectionHeading>
            <ChildListingMap data={data} />
            <ChildListingSupplementaryInfo
                contactAgentEl={contactAgentEl}
                inspectionTimesEl={inspectionTimesEl}
                setInspectionTimesEl={setInspectionTimesEl}
                propertyAddress={propertyOrProjectAddress}
                displayAddress={displayAddress}
                getDirectionsTracker={getDirectionsTracker}
                data={data}
                inspectionsEl={inspectionsEl}
            />
        </div>
    );
};
