/* @flow */
import { type Channel as ChannelType } from '../../../../models/Channel';
import type { SaveToCalendarProps } from '../auction/adaptLexaData';
import type { OpenTimesProps } from '../calendar-event/types';
import type { DisplaySuiteData } from '../../project-display-map/new-supplementary-info/types';

const constructDisplaySuite = ({
    displaySuiteInspections,
    address,
    listingId,
    eventTrackers,
    addToPlan,
    channel,
    registrationEventTrackers,
}): Array<SaveToCalendarProps> =>
    displaySuiteInspections.map((inspection) => {
        const trackingData = {
            startTime: inspection.startTime,
            endTime: inspection.endTime,
            status: channel === 'sold' ? 'sold' : 'active',
            channel: channel,
        };
        return {
            address,
            event: inspection,
            eventTrackers: eventTrackers,
            listingId,
            trackAddToPlan: addToPlan({ ...trackingData, itemType: 'display_suite' }),
            registrationEventTrackers: {
                inspectionRegistrationFormOpen: registrationEventTrackers.inspectionRegistrationFormOpen(trackingData),
                inspectionRegistrationFormSubmitted:
                    registrationEventTrackers.inspectionRegistrationFormSubmitted(trackingData),
            },
        };
    });

export default (channel: ChannelType, shouldHideTitle: boolean) =>
    (data: DisplaySuiteData): OpenTimesProps => {
        const { listing, displaySuiteInspections, eventTracker } = data;
        const { id: listingId } = listing;
        const address = listing.displayLocation ? listing.displayLocation.address : listing.address;
        const calendarEventTrackers = (inPageSource?: string) => {
            const trackSaveToCalendar = eventTracker.saveToCalendar('display_suite', inPageSource);
            const trackSaveToCalendarSuccess = eventTracker.saveToCalendarSuccess('display_suite', inPageSource);
            return {
                trackSaveToCalendar,
                trackSaveToCalendarSuccess,
            };
        };

        return {
            title: shouldHideTitle ? null : 'Display suite open hours',
            listingId,
            generalFeatures: undefined,
            propertySizes: undefined,
            templatedUrl: listing.media.mainImage.templatedUrl,
            registrationsEnabled: false,
            inspectionDescription: listing.displayLocation?.description,
            openTimes: constructDisplaySuite({
                displaySuiteInspections,
                listingId,
                address,
                eventTrackers: calendarEventTrackers,
                addToPlan: eventTracker.addToPlan,
                channel: channel,
                registrationEventTrackers: {
                    inspectionRegistrationFormOpen: eventTracker.inspectionRegistrationFormOpen,
                    inspectionRegistrationFormSubmitted: eventTracker.inspectionRegistrationFormSubmitted,
                },
            }),
        };
    };
