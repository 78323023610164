/* @flow */
import React, { useState } from 'react';
import PrivacyStatement from '../../../privacy-statement';
import styled from 'styled-components';
import { Button, paletteHelper, spacingHelper, Text } from '@rea-group/construct-kit-core';

const PrivacyButton = styled(Button)`
    height: 50px;
    ${({ theme }) => theme.breakpoints.medium} {
        height: 100px;
    }
`;
export const StyledStatement = styled(Text).attrs({ forwardedAs: 'div', variant: 'body02' })`
    color: ${paletteHelper('textSecondary')};
    border-top: 1px solid ${paletteHelper('borderSecondary')};
    padding: ${spacingHelper('medium 0')};
`;
const CollectionStatement = () => {
    const [showCollectionStatement, revealShowCollectionStatement] = useState(false);

    return (
        <Text as="div" variant="body02">
            <PrivacyButton
                variant="link-secondary"
                type="button"
                aria-pressed={showCollectionStatement}
                onClick={() => revealShowCollectionStatement(!showCollectionStatement)}
            >
                Personal Information Collection Statement
            </PrivacyButton>
            {showCollectionStatement && (
                <StyledStatement>
                    <PrivacyStatement />
                </StyledStatement>
            )}
        </Text>
    );
};

export default CollectionStatement;
